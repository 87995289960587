
.container {
  padding-top: 0;
  padding-bottom: 20px;
}
.columns {
  margin: 0;
  flex-grow: 1;
  .column {
    padding: 0;
    &.results-list {
      padding-right: 30px;
      border-right: 1px solid #ccc;
      padding-top: 0;
      @media only screen and (max-width: 1200px) {
        width: 100%;
        border-right: 0;
        padding-right: 0;
      }
    }
    &.result-component {
      z-index: 50;
      padding-left: 30px;
    }
    @media only screen and (max-width: 1200px) {
      &.result-component {
        padding-left: 0;
        width: 0;
        left: 110%;
        transition: all 0.5s;
        position: absolute;
        top: -80px;
        height: 100svh;
        background: #f0f0f0;
        -webkit-box-shadow: -1px -1px 3px 1px rgba(0, 0, 0, 0.2);
        box-shadow: -1px -1px 3px 1px rgba(0, 0, 0, 0.2);
        &.active {
          left: 10%;
          width: 90%;
        }
      }
    }
  }
}
.result-card {
  border-radius: 10px;
  padding: 10px 15px;
  &.active {
    background: #007aff;
    color: #fff;
    .title,
    small {
      color: inherit;
      opacity: 1;
    }
  }
  .column {
    display: flex;
    align-items: center;
    padding: 0;
    line-height: 1;
    .title {
      margin-bottom: 0;
    }
    small {
      font-size: 0.8rem;
    }
    .mark {
      position: relative;
      width: 100%;
      height: 100%;
      border-radius: 5px;
      background: #fff;
      color: #000;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 0.9rem;
    }
  }
}
.list-name {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.pagination {
  float: right;
  margin-top: 20px;
  ::v-deep .info {
    display: none;
  }
  ::v-deep a.pagination-link {
    background: #fff;
    border: 0;
  }
}
