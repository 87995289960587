
.container {
  padding: 0;
}
@media only screen and (max-width: 1200px) {
  .container {
    padding: 10px 20px !important;
  }
}
@media only screen and (max-width: 800px) {
  .container {
    padding: 20px 10px !important;
  }
}

.columns {
  margin-left: 0;
  margin-right: 0;
}

#close-result {
  display: none;
  position: absolute;
  top: 10px;
  right: 10px;
}

@media only screen and (max-width: 1200px) {
  #close-result {
    display: block;
  }
}
.results {
  margin-top: 20px;
  .column {
    height: 150px;
    margin: 20px;
    margin-top: 50px;
    border-radius: 20px;
    padding: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 3px solid transparent;
    transition: all 0.3s;
    .title {
      margin-bottom: 5px;
    }
    &.tall {
      height: 200px;
      margin-top: 0px;
    }
    &:first-child {
      margin-left: 0px;
    }
    &:last-child {
      margin-right: 0px;
    }
    &.purple {
      background: linear-gradient(#fff, #fff) padding-box, linear-gradient(135deg, #595bd8, #c472ff) border-box;
      color: #5c5bd9;
      .title {
        color: #595bd8;
      }
    }
    &.green {
      background: linear-gradient(#fff, #fff) padding-box, linear-gradient(135deg, #2DD634, #14a92f) border-box;
      color: #2DD634;
      .title {
        color: #14a92f;
      }
    }
    &.red {
      background: linear-gradient(#fff, #fff) padding-box, linear-gradient(135deg, #ee4868, #db443e) border-box;
      color: #db443e;
      .title {
        color: #db443e;
      }
    }

    &.gold {
      position: relative;
      overflow: hidden;
      width: 33.3%;
      height: 200px;
      background: #f5c21b;
      @media only screen and (min-width: 1200px) {
        &:after {
          animation: shine 4s ease-in-out infinite;
          animation-fill-mode: forwards;
          content: "";
          position: absolute;
          top: -110%;
          left: -210%;
          width: 200%;
          height: 200%;
          opacity: 0;
          transform: rotate(30deg);

          background: rgba(255, 255, 255, 1);
          background: linear-gradient(to right, rgba(255, 255, 255, 0.13) 0%, rgba(255, 255, 255, 0.13) 77%, rgba(255, 255, 255, 0.5) 92%, rgba(255, 255, 255, 0) 100%);
        }
      }
    }

    @media only screen and (max-width: 1900px) {
      &.result-block {
        padding: 20px;
        height: 120px;
        margin-top: 20px;
        border: 4px solid transparent;
        &.tall {
          height: 140px;
          margin-top: 0;
        }
      }
    }
    @media only screen and (max-width: 1500px) {
      &.result-block {
        margin: 20px 5px 5px 5px;
        height: 100px;
        border: 3px solid transparent;
        padding: 20px 10px 20px 20px;
        &.tall {
          height: 120px;
          margin-top: 0;
        }
        .title.is-6 {
          font-size: 0.8rem;
        }
        .title.is-3 {
          font-size: 1.3rem;
        }
        ::v-deep .mdi::before {
          font-size: 2rem;
        }
      }
    }
    @media only screen and (max-width: 1200px) {
      &.result-block {
        padding: 10px 10px 10px 20px;
        height: 50px;
        border-radius: 10px;
        &.tall {
          height: 70px;
        }
        .title {
          margin: 0;
        }
        .title.is-6 {
          font-size: 0.5rem;
        }
        .title.is-3 {
          font-size: 1rem;
        }
        ::v-deep .icon {
          width: 20px;
          .mdi::before {
            font-size: 1.1rem;
          }
        }
      }
    }
  }
}

.simple-card {
  border-radius: 10px;
  padding: 2px 10px;
  margin-bottom: 20px;
  background: #fff;
  &.transparent {
    background: none;
    padding: 0 30px;
    font-weight: 600;
    margin-bottom: 5px;
    margin-top: 20px;
  }
  .column.is-flex {
    align-items: center;
    justify-content: center;
  }
  @media only screen and (max-width: 800px) {
    padding: 2px 5px !important;
  }
}

.flag {
  margin-top: -5px !important;
}

@keyframes shine {
  10% {
    opacity: 1;
    top: -30%;
    left: -30%;
    transition-property: left, top, opacity;
    transition-duration: 1s, 1s, 0.15s;
    transition-timing-function: ease;
  }
  100% {
    opacity: 0;
    top: -30%;
    left: -30%;
    transition-property: left, top, opacity;
  }
}
